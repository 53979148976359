@keyframes save-invest {
  0% {
    opacity: 0;
    transform: rotate(-20deg);
  }

  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
}

@keyframes save-invest-front {
  0% {
    opacity: 0;
    transform: translateX(15px);
  }

  100% {
    opacity: 1;
    transform: translateX(-5px);
  }
}

@keyframes loading-fade-scale {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }

  50% {
    transform: translateX(5px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes loading-fade-scale-left {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(-5px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes loading-fade {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }

  50% {
    transform: translateX(15px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes loading-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }

  50% {
    transform: translateX(-15px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes loading-fade-round-from-right {
  0% {
    clip-path: circle(0% at 50% 50%);
  }

  100% {
    clip-path: circle(60% at 70% 50%);
  }
}

@keyframes loading-fade-round-from-bottom {
  0% {
    clip-path: circle(40% at 50% 100%);
  }

  100% {
    clip-path: circle(100% at 50% 120%);
  }
}

@keyframes loading-fade-bottom {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes list-fade {
  0% {
    opacity: 0;
    transform: translateZ(70px) rotateY(15deg);
  }

  100% {
    opacity: 1;
    transform: translateZ(0px) rotateY(0deg);
  }
}

.list-fade {
  transition: all 0.3s cubic-bezier(0.79, 0.33, 0.14, 0.53);
}

.list-fade:nth-child(0) {
  animation: list-fade 0.4s 1 linear both;
  animation-delay: 0s;
}

.list-fade:nth-child(1) {
  animation: list-fade 0.5s 1 linear both;
  animation-delay: 0.1s;
}

.list-fade:nth-child(2) {
  animation: list-fade 0.6s 1 linear both;
  animation-delay: 0.2s;
}

.list-fade:nth-child(3) {
  animation: list-fade 0.6s 1 linear both;
  animation-delay: 0.3s;
}

.list-fade:nth-child(4) {
  animation: list-fade 0.7s 1 linear both;
  animation-delay: 0.4s;
}

.list-fade:nth-child(5) {
  animation: list-fade 0.7s 1 linear both;
  animation-delay: 0.5s;
}

.list-fade:nth-child(6) {
  animation: list-fade 0.8s 1 linear both;
  animation-delay: 0.6s;
}

.list-fade:nth-child(7) {
  animation: list-fade 0.9s 1 linear both;
  animation-delay: 0.7s;
}

.list-fade:nth-child(8) {
  animation: list-fade 1s 1 linear both;
  animation-delay: 0.8s;
}

.fade-bottom {
  animation: loading-fade-bottom 0.6s 1 linear both;
}

.fade-right {
  animation: loading-fade-scale 0.4s 1 linear both;
}